const routes = [
    {
        path: '/reuniones',
        name: 'reuniones',
        component: () => import('../views/reuniones/ReunionesView')
    },
    {
        path: '/reuniones/modi/:id',
        name: 'reuniones-modi',
        component: () => import('../views/reuniones/ReunionesModifView')
    },
    {
        path: '/reuniones/consulta/:id',
        name: 'reuniones-consulta',
        component: () => import('../views/reuniones/ReunionesConsultaView')
    }
]

export default routes