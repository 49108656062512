import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import storage from './storage';

import 'bootstrap/dist/css/bootstrap.css'
import '@/assets/material-design-icons.css'
import '@/assets/app.css'
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.js'

var app = createApp(App);

// variables globales
// app.config.globalProperties.global_UPLOADS_URL_BASE = 'http://localhost:3000/';
// app.config.globalProperties.global_API_URL_BASE = 'http://localhost:3000/api/';
app.config.globalProperties.global_UPLOADS_URL_BASE = 'https://appsntsa-api-service.onrender.com/';
app.config.globalProperties.global_API_URL_BASE = 'https://appsntsa-api-service.onrender.com/api/';
app.config.globalProperties.global_MSG_NO_REGISTROS = 'No hay registros para mostrar';

const global = app.config.globalProperties;
export { global }

// montar aplicación
app.use(bootstrap).use(router).use(storage).mount('#app');
