const routes = [
  {
      path: '/adscripciones',
      name: 'adscripciones-listar',
      component: () => import(/* webpackChunkName: "adscripciones" */ '../views/adscripciones/AdscripcionesView.vue')
  },
  {
      path: '/adscripciones/alta',
      name: 'adscripciones-alta',
      component: () => import (/* webpackChunkName: "adscripcionesAltaView"*/ '../views/adscripciones/AdscripcionesAltaView.vue')
  },
  {
      path: '/adscripciones/modi/:id',
      name: 'adscripciones-modi',
      component: () => import (/* webpackChunkName: "adscripcionesModiView"*/ '../views/adscripciones/AdscripcionesModiView.vue')
  }
]

export default routes
