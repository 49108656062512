const routes = [
  {
    path: '/personal',
    name: 'personal',
    component: () => import(/* webpackChunkName: "personal" */ '../views/personal/PersonalView')
  },
  {
      path: '/personal/alta',
      name: 'personal-alta',
      component: () => import (/* webpackChunkName: "personalAltaView"*/ '../views/personal/PersonalAltaView.vue')
  },
  {
    path: '/personal/modi/:id',
    name: 'personal-modi',
    component: () => import(/* webpackChunkName: "personalModiView" */ '../views/personal/PersonalModiView')
  }
]

export default routes
