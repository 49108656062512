import { createStore } from 'vuex'
import router from '../router'

const storage = createStore({
    state: {
        logueado: false,
        usuarioNombre: ''
    },
    mutations: {
        iniciarSesion(state, usuarioNombre) {
            state.logueado = true;
            state.usuarioNombre = usuarioNombre;
        },
        cerrarSesion(state) {
            router.push('/login');
            state.logueado = false;
            state.usuarioNombre = '';
        }
    }
});

export default storage