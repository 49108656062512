const routes = [
  {
      path: '/efemerides',
      name: 'efemerides-listar',
      component: () => import(/* webpackChunkName: "efemerides" */ '../views/efemerides/EfemeridesView.vue')
  },
  {
      path: '/efemerides/alta',
      name: 'efemerides-alta',
      component: () => import (/* webpackChunkName: "efemeridesAltaView"*/ '../views/efemerides/EfemeridesAltaView.vue')
  },
  {
      path: '/efemerides/modi/:id',
      name: 'efemerides-modi',
      component: () => import (/* webpackChunkName: "efemeridesModiView"*/ '../views/efemerides/EfemeridesModiView.vue')
  }
]

export default routes
