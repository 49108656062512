import { createRouter, createWebHistory } from 'vue-router'

import storage  from '@/storage'

import PuestosRoutes from '../router/PuestosRoutes'
import AdscripcionesRoutes from '../router/AdscripcionesRoutes'
import BonosRoutes from '../router/BonosRoutes'
import GruposDocumentosRoutes from '../router/GruposDocumentosRoutes'
import PersonalRoutes from '../router/PersonalRoutes'
import ReunionesRoutes from '../router/ReunionesRoutes'
import ConsultasRoutes from '../router/ConsultasRoutes'
import EfemeridesRoutes from '../router/EfemeridesRoutes'
import AgendaRoutes from '../router/AgendaRoutes'
import PanelRoutes from '../router/PanelRoutes'
import LogrosRoutes from '../router/LogrosRoutes'

let DefaultRoutes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue'),
    meta: {
      hideNavBar: true
    }
  }
]

let routes = [
    ...DefaultRoutes, ...PuestosRoutes, 
    ...AdscripcionesRoutes, ...BonosRoutes, 
    ...GruposDocumentosRoutes, ...PersonalRoutes,
    ...ReunionesRoutes, ...ConsultasRoutes,
    ...EfemeridesRoutes, ...AgendaRoutes,
    ...PanelRoutes, ...LogrosRoutes
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  if (to.path == '/login') {
    next();
  } else {
    if (storage.state.logueado)
      next();
    else
      next('/login');
  }
});

export default router
