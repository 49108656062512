const routes = [
    {
        path: '/puestos',
        name: 'puestos-listar',
        component: () => import(/* webpackChunkName: "puestos" */ '../views/puestos/PuestosView.vue')
    },
    {
        path: '/puestos/alta',
        name: 'puestos-alta',
        component: () => import (/* webpackChunkName: "puestosAltaView"*/ '../views/puestos/PuestosAltaView.vue')
    },
    {
        path: '/puestos/modi/:id',
        name: 'puestos-modi',
        component: () => import (/* webpackChunkName: "puestosModiView"*/ '../views/puestos/PuestosModiView.vue')
    }
]

export default routes
