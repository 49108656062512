const routes = [
  {
      path: '/agenda',
      name: 'agenda-listar',
      component: () => import(/* webpackChunkName: "agenda" */ '../views/agenda/AgendaView.vue')
  },
  {
      path: '/agenda/alta',
      name: 'agenda-alta',
      component: () => import (/* webpackChunkName: "agendaAltaView"*/ '../views/agenda/AgendaAltaView.vue')
  },
  {
      path: '/agenda/modi/:id',
      name: 'agenda-modi',
      component: () => import (/* webpackChunkName: "agendaModiView"*/ '../views/agenda/AgendaModiView.vue')
  }
]

export default routes
