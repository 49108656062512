const routes = [
    {
        path: '/logros',
        name: 'logros-listar',
        component: () => import(/* webpackChunkName: "logros" */ '../views/logros/LogrosView.vue')
    },
    {
        path: '/logros/alta',
        name: 'logros-alta',
        component: () => import (/* webpackChunkName: "logrosAltaView"*/ '../views/logros/LogrosAltaView.vue')
    },
    {
        path: '/logros/modi/:id',
        name: 'logros-modi',
        component: () => import (/* webpackChunkName: "logrosModiView"*/ '../views/logros/LogrosModiView.vue')
    }
  ]
  
  export default routes
  