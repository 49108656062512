const routes = [
  {
      path: '/bonos',
      name: 'bonos-listar',
      component: () => import(/* webpackChunkName: "bonos" */ '../views/bonos/BonosView.vue')
  },
  {
      path: '/bonos/alta',
      name: 'bonos-alta',
      component: () => import (/* webpackChunkName: "bonosAltaView"*/ '../views/bonos/BonosAltaView.vue')
  },
  {
      path: '/bonos/modi/:id',
      name: 'bonos-modi',
      component: () => import (/* webpackChunkName: "bonosModiView"*/ '../views/bonos/BonosModiView.vue')
  }
]

export default routes
