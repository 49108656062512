const routes = [
  {
      path: '/grupos-documentos',
      name: 'grupos-documentos-listar',
      component: () => import(/* webpackChunkName: "grupos-documentos" */ '../views/grupos-documentos/GrupoDocumentosView.vue')
  },
  {
      path: '/grupos-documentos/alta',
      name: 'grupos-documentos-alta',
      component: () => import (/* webpackChunkName: "gruposDocumentosAltaView"*/ '../views/grupos-documentos/GrupoDocumentosAltaView.vue')
  },
  {
      path: '/grupos-documentos/modi/:id',
      name: 'grupos-documentos-modi',
      component: () => import (/* webpackChunkName: "gruposDocumentosModiView"*/ '../views/grupos-documentos/GrupoDocumentosModiView.vue')
  }
]

export default routes
