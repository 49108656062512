<template>
    <nav v-if="!$route.meta.hideNavBar"  class="navbar navbar-expand-lg bg-light">
        <div class="container-fluid">
            <router-link to="/" class="navbar-brand">SNTSA</router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item dropdown">
                        <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">Catálogos</a>
                        <ul class="dropdown-menu">
                            <li><router-link class="dropdown-item" to="/puestos">Puestos</router-link></li>
                            <li><router-link class="dropdown-item" to="/adscripciones">Adscripciones</router-link></li>
                            <li><router-link class="dropdown-item" to="/bonos">Bonos</router-link></li>
                            <li><router-link class="dropdown-item" to="/grupos-documentos">Grupos de Documentos</router-link></li>
                            <li><hr class="dropdown-divider"></li>
                            <li><router-link class="dropdown-item" to="/personal">Personal</router-link></li>
                        </ul>
                    </li>
                    <li class="nav-item">
                        <router-link to="/panel" class="nav-link">Panel</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/reuniones" class="nav-link">Asambleas</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/consultas" class="nav-link">Consultas</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/efemerides" class="nav-link">Efemerides</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/agenda" class="nav-link">Agenda</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/logros" class="nav-link">Logros</router-link>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" @click="cerrarSesion">Cerrar Sesión</a>
                    </li>
                    <span>Usuario: {{ $store.state.usuarioNombre }}</span> 
                    <!-- <li class="nav-item">
                        <router-link to="/gastos" class="nav-link">Gastos</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/becas" class="nav-link">Becas</router-link>
                    </li> -->
                </ul>
            </div>
        </div>
    </nav>
    
    <router-view/>

    <footer>
        <p>SNTSA Sección No. 48</p>
    </footer>
</template>

<script>
export default {
    methods: {
        cerrarSesion() {
            this.$store.commit('cerrarSesion');
        }
    }
}
</script>

<style>
#app {
    font-size: 14px;
}

.container {
    min-height: 550px;
}

nav {
  padding: 30px;
  margin-bottom: 20px;
}

footer {
    text-align: center;
    margin-top: 20px;
}

a:hover {
    cursor: pointer;
}
</style>
